.mainFormContainer{
    display: flex;
    justify-content: start;
    /* border: 2px solid grey; */
    margin: 5px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    background-image: linear-gradient(to right top, #313131, #2f2f2f, #2d2d2d, #2b2b2b, #292929);
}


.Container{
    width: 30%;
    display: flex;
    flex-direction: column;
}

.formContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    border: 1px solid rgb(50, 50, 50);
    margin: 10px;
    box-shadow: 5px 5px 15px;
    background-image: linear-gradient(to right top, #5b5b5b, #4f4f4f, #434343, #383838, #2d2d2d);
}

 
.formHeading{
    border-bottom: 2px solid white;
    width: 85%;
    margin: 15px;
    padding-bottom: 10px;
    text-align: center;
}

.formTag{
    width: 100%;
}

.fieldContainer{
    margin: 10px;
    padding-bottom: 10px;
    text-align: center;
}



.inputField{
    width: 90%;
    outline: none;
    border-radius: 10px;
    border: 1px solid rgb(53, 53, 53);
    height: 30px;
    color: white;
    margin: 2px;
    padding-bottom: 3px;
    font-size: 13px;
    text-align: center;
    background-image: linear-gradient(to right top, #6c6c6c, #626262, #585858, #4e4e4e, #454545);

}


.inputField:hover{
    border: 2px solid white;
    background-image: linear-gradient(to right top, #686868, #707070, #787878, #818181, #898989);
}



.actionSection{
    text-align: center;
}

.resetBtn , .submitBtn{
    border-radius: 10px;
    font-size: 15px;
    border: 2px solid black;
    background-color: #9b9b9b;
    padding: 10px;
    margin: 5px;
    width: 45%;
}


.resetBtn:hover , .submitBtn:hover{
    background-color: #5e5c5c;
}






.tableData{
    width: 65%;
    margin: 10px auto;
    /* border: 2px solid rgba(12, 12, 12, 0.886); */
}


@media (max-width: 800px) {
    .mainFormContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .Container {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .formContainer {
        width: 50%;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .formTag {
        width: 100%;
    }

    .actionSection {
        width: 50%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tableData {
        width: 100%;
        overflow-x: auto;
    }

    .table {
        width: 100%;
        table-layout: auto;
    }

    .inputField {
        width: 100%;
        box-sizing: border-box; /* Ensures padding and borders are included in the width */
        margin-bottom: 10px;
    }

    .resetBtn, .submitBtn {
        width: 100%;
        margin-bottom: 10px;
    }
}